﻿$filter-area-min-row-height: 50px;
$filter-block-height: 89px;

.filter-area {
    border: 2px solid #DEDEDE;
    margin-bottom: 10px;
    overflow-y: auto;

    .filter-area-list {
        .filter-area-list-item {
            min-height: $filter-area-min-row-height;
            border-bottom: 1px solid #DEDEDE;
            cursor: pointer;

            .heading {
                color: $light-text-color-on-gray;
                font-size: 12px;
            }

            .filter-item-with-icons {
                display: flex;

                .filter-area-item-heading {
                    flex: 1;
                }

                .item-icons {
                    float: right;
                    display: inline-flex;

                    div {
                        margin-right: 10px;
                    }
                }
            }
        }

        .filter-area-no-result {
            text-align: center;
            width: 100%;
        }
    }

    .filter-block {
        padding: 10px 10px 20px 10px;
        border-bottom: 1px solid #DEDEDE;
        height: $filter-block-height;
    }
}

.filter-area-height-admin-users {
    height: $admin-users-tab-filter-area-height;
}

.filter-area-height-admin-groups {
    height: $admin-groups-tab-filter-area-height;
}
