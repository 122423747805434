﻿
// Navbar variable overrides
$navbar-height:                         40px;
$navbar-inverse-link-active-bg:         #222; // Keep the background colour of an active item the same as the navbar
$navbar-inverse-border:                 #222; // Keep the border but change the colour to the background of the navbar

// Forms
$input-bg:                              #F1F1F1;
$input-border:                          #EEE;
$input-border-radius:                   auto;
$input-border-radius-large:             auto;
$input-border-radius-small:             auto;

// Input groups
$input-group-addon-bg:                  #E0E0E0;


// Typography
$font-family-sans-serif:                RobotoRegular, "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:                     RobotoRegular, Georgia, "Times New Roman", Times, serif;
$font-family-base:                      $font-family-sans-serif;

$headings-font-family:                  $font-family-sans-serif;
$text-muted:                            #757575;


/* Change from weight of 500 to bold to ensure RobotoRegular displays in bold */
$headings-font-weight:                  bold !default;


// Modals
$modal-backdrop-bg:                     #FFF;
$modal-backdrop-opacity:                .75;


$hr-border:                             #DADADA;


// NavBar and pagination styles

// The colour of the active navigation bar used on the top nav and pagination
$active-nav-border-color: #619FD0;
$active-nav-border-light-color: #C3DAED;
$active-nav-text-dark-color: #333;
// The background colour of the active navigation item
$active-nav-bg: #F6F9FB;
$active-highlight-nav-bg: $input-group-addon-bg;

// The light border colour used on the navs
$light-nav-border-color: #F0F4F7;

// The thickness of the active navigation bar dependant on orientation
$active-nav-x-border: 3px solid $active-nav-border-color;
$active-nav-y-border: 4px solid $active-nav-border-color;

// The thickness of the hover navigation bar dependant on orientation
$hover-active-nav-x-border: 3px solid $active-nav-border-light-color;
$hover-active-nav-y-border: 4px solid $active-nav-border-light-color;

// Apply thickness of the active navigation bar in white to non-active items
$non-active-nav-x-border: 3px solid #FFF;
$non-active-nav-y-border: 4px solid #FFF;

// Custom tabs background color
$tabs-background-color: #f2f2f2;

// help icon colour
$help-icon-color: #7F5DCF;
