﻿.p-calendar {
    height: 34px;
    width: 100%;

    .p-inputtext {
        background-color: $background-light-grey;
        padding: 6px 12px;
        font-size: 14px;
        border: 1px solid #EEE;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        border-radius: initial;

        &:enabled:hover {
            border-color: $input-focus-border-color;
            outline: 0;
            box-shadow: $input-focus-box-shadow;
            border-radius: initial;
        }
    }

    &.has-error {
        .p-inputtext {
            border-color: $validation-error-color;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

            &:enabled:hover {
                border-color: $input-error-border-color;
                box-shadow: $input-error-box-shadow;
            }
        }
    }
}

.p-datepicker {
    font-size: 1.4rem; // html - 10px, body - 14px
}

.p-datepicker table {
    font-size: 1.4rem;
}

.p-datepicker .p-timepicker span {
    font-size: 1.4rem;
}

.p-datepicker table th {
    text-align: center;
}