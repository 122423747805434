﻿.filter-panel__heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.9em;
    min-height: $requests-filter-heading-and-sort-by-min-height;
}

.filter-panel__heading {
    font-size: 1.25em;
    font-weight: bold;
}


.filter-panel {
    border-top: 1px solid #ddd;
}

.filter-panel__list--no-bullet {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 10px;
}

.filter-panel__tooltip {
    max-width: 190px;    
}

.filter-panel__tooltip-arrow {
   scale: 3;
   margin-bottom: 5px;
}

.filter-panel__tooltip-text {
    font-family: $font-family-sans-serif;
    font-size: 12px;
    text-align: center;
    margin-bottom: 5px;
}

.filter-panel__item,
.filter-panel__item:visited,
.filter-panel__item:hover,
.filter-panel__item:focus,
.filter-panel__item:active {
    text-decoration: none;
}

.filter-panel__item-help {
    color: $help-icon-color;
    margin-left: 5px;
}

.filter-panel__item:before {
    font-family: "Font Awesome 6 Pro";
    // renders the content icon "fas fa-caret-down" from https://fontawesome.com/icons/caret-down
    content: "\f0d7";
    display: inline-block;
    padding-right: 2px;
    font-weight: 900;
    transition: 0.2s transform ease-in-out; // Add a transition just on the transform
}

.filter-panel__item.collapsed:before {
    transform: rotate(-90deg); // Rotate the down arrow so it points in the direction of a right arrow
}

.filter-panel__values--scrollable {
    max-height: 350px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.filter-panel__values--scrollable:hover {
    overflow-y: auto;
}

.filter-panel__value--text {
    font-weight: 400;
}

.filter-panel__value-icon {
    color: $icon-with-text-color;
}

.filter-panel__values--indented {
    padding-left: 10px;
}

.filter-panel__values--animated {
    transition: 0.2s ease-in-out;
}

.filter-panel__text-filter--indented {
    padding-left: 0px;
}

.filter_panel__text-filter-form {
    margin-bottom: 10px;
    width: 100%;
    display: block;
    padding: 0 0 0 15px;
    display: flex;
    flex-wrap: wrap;
}

.filter_panel__text-filter-button {
    padding: 2px 4px;
    font-size: 12px;
    min-width: 30px;
    text-align: center;
    margin-top: 2px;
    height: 26px;
}

.filter_panel__text-filter-form--text {
    width: 83%;
    height: 30px;
    padding-top: 4px;
    margin: 1px 5.5px 0 0;
    font-size: 13px;
    flex: 1;
}