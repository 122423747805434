﻿.upload-dropdown-menu {
    padding: 0;
}

.dropdown-menu {
    > li.disabled > span {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: normal;
        line-height: $line-height-base;
        color: $dropdown-link-disabled-color;
        white-space: nowrap; // prevent links from randomly breaking onto new lines
        cursor: $cursor-disabled;

        &.version-number {
            color: $light-text-color;
            font-size: 13px;
        }
    }
}

.dropdown-menu-full-width {
    display: inline;
    width: 100%;
}

.dropdown-btn-sm-default {
    line-height: 1.5;
    vertical-align: middle;
    font-size: $font-size-small;
    border-radius: $btn-border-radius-small;
    padding: 5px 0px 5px 10px;
    border-color: $btn-default-border;

    &:hover, &.p-dropdown.p-focus {
        border-color: $btn-default-hover-border;
        background-color: $btn-default-hover-background;
        box-shadow: none;
    }

    &.p-dropdown.p-focus {
        outline: auto;
        outline-offset: -2px;
    }

    .p-dropdown-label.p-inputtext {
        padding: 0;
        font-size: $font-size-small;
        font-family: $font-family-sans-serif;
        color: $btn-default-color;
    }
}

.dropdown-sm-panel {
    font-family: $font-family-sans-serif;

    .p-dropdown-items {

        .p-dropdown-item {
            font-size: $font-size-small;
        }
    }
}

.dropdown-option {
    white-space: normal;
}