// Request details component - displays the main content on the left and the discussion messages area on the right

// The container for the main content and discussion areas on new dynamic form based requests
.request-details__container {
  display: flex;
  flex-wrap: wrap;
}


// On small viewports, the main content and discussion area (if allowed) are shown stacked on top of each other
.request-details__main-content {
  width: 100%
}

// By default the discussion area is not displayed.
// It is only displayed if discussion messaging is allowed.
.request-details__discussion {
  display: none;
  width: 100%;
}

// By default the vertical divider is not displayed.
// It is only displayed on larger viewports when items side-by-side and if discussion messaging is allowed.
.request-details__vertical-divider {
  display: none;
  border-left: 1px solid #E0E0E0;
  width: 1px;
}

// ----------------------------------------------------------------------------------------------------
// These styles should conditionally be applied to the elements only if discussion messaging is allowed
// ----------------------------------------------------------------------------------------------------

.request-details__discussion--discussion-allowed {
  display: block;
}

@media screen and (min-width: $screen-md-min) {
  .request-details__main-content--discussion-allowed {
    flex: 1; // Use up the rest of the remaining width
  }

  .request-details__discussion--discussion-allowed {
    width: 355px;
  }

  .request-details__vertical-divider--discussion-allowed {
    display: block; // Show the vertical divider when items displayed next to each other
  }
}

@media screen and (min-width: $screen-xl-min) {
  // On extra large viewports, make the discussion area slightly wider
  .request-details__discussion--discussion-allowed {
    width: 370px;
  }
}

// ----------------------------------------------------------------------------------------------------



// Contains the HTML heading and the reload button
.request-details__discussion-heading-container {
  display: flex;
}

.request-details__discussion-heading-text {
  flex: 1;
}

.request-details__discussion-heading-button {
  margin-top: 18px; // Bring button down inline with the heading
}

.request-details__divider-button-container {
    display: inline-block;
    margin-right: 15px;
    border-right: 1px solid #E0E0E0; 
    padding-right: 15px;
}

.request-details__uploads-loading-error-container {
    text-align: center;

    i {
        font-size: 3em;
    }

    div {
        margin-top: 0.5em;
        font-weight: bold;
    }
}