﻿nav.pagination-container {
    display: flex;
    align-items: flex-end;
    margin: 20px 0;

    div.pagination-size-selector-container {
        color: $link-hover-color;
        flex-grow: 1;
        text-align: right;

        .p-dropdown {

            .p-dropdown-label {
                font-size: $font-size-base;
            }
        }
    }

    ul.pagination {
        margin: 0px;
        flex-grow: 1;

        li {
            &:first-child > a,
            &:last-child > a {
                border-radius: 0 0 0 0;
            }

            > a {
                padding: 8px 16px; // Allow for a bigger tap target
                border-top: $non-active-nav-x-border;
                border-left: 1px solid $light-nav-border-color;
                border-right: 1px solid $light-nav-border-color;
                border-bottom: none;
            }

            &:first-child > a {
                border-left: none;
            }

            &:last-child > a {
                border-right: none;
            }

            > a:hover,
            > a:focus {
                border-top: $non-active-nav-x-border;
                background-color: #FFF;
                border-left-color: $light-nav-border-color;
                border-right-color: $light-nav-border-color;
            }

            > a:hover {
                border-top: $hover-active-nav-x-border;
            }

            &.active > a,
            &.active > a:hover,
            &.active > a:focus {
                // Use darker colour to ensure contrast between foreground and background colors meets WCAG 2 AA
                color: $link-hover-color;
                background-color: $active-nav-bg;
                border-top: $active-nav-x-border;
                border-left: 1px solid $light-nav-border-color;
                border-right: 1px solid $light-nav-border-color;
            }

            &:first-child.active > a,
            &:first-child.active > a:hover,
            &:first-child.active > a:focus {
                border-left: none;
            }

            &:last-child.active > a,
            &:last-child.active > a:hover,
            &:last-child.active > a:focus {
                border-right: none;
            }
        }
    }
}

.pagination-size-selector-dropdown {

    .p-dropdown-items {

        .p-dropdown-item {
            border-left: 3px solid transparent;
            font-size: $font-size-base;

            &:not(.p-highlight):not(.p-disabled) {
                &:hover, &.p-focus {
                    background-color: $active-nav-bg;
                    color: $link-hover-color;
                    border-left: $hover-active-nav-x-border;
                }
            }

            &.p-highlight, &.p-highlight.p-focus {
                border-left: $active-nav-x-border;
                background: $active-nav-bg;
                color: $link-hover-color;
            }
        }
    }
}