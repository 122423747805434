.p-listbox-header {
    padding: 1.75rem 1rem;
    padding-top: 0rem;
    background: unset;

    .p-listbox-filter-container {
        .p-inputtext {
            background-color: $background-light-grey;
            padding: 6px 12px;
            font-size: 14px;
            border: 1px solid #EEE;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
            border-radius: initial;

            &:enabled:focus {
                border-color: $input-focus-border-color;
                outline: 0;
                box-shadow: $input-focus-box-shadow;
                border-radius: initial;
            }
        }
    }
}

.p-listbox,
.p-listbox-item {
    margin: unset;
    padding: unset;
    border: unset;
    color: unset;
    transition: unset;
    border-radius: unset;
    font-family: unset;
    font-feature-settings: unset;
    font-size: unset;
    font-weight: unset;
}

.p-listbox-item {
    padding: 5px 0 0 5px;
    border-left: $non-active-nav-y-border;

    &.p-highlight {
        background-color: $active-nav-bg;
        border-left: $active-nav-y-border;
    }

    &.p-focus {
        border-left: $hover-active-nav-y-border;
    }

    &.p-highlight.p-focus {
        background-color: $active-highlight-nav-bg;
        border-left: $active-nav-y-border;
    }

    &:hover {
        border-left: $hover-active-nav-y-border;

        &.p-highlight {
            background-color: $active-highlight-nav-bg;
            border-left: $active-nav-y-border;
        }
    }
}

.p-listbox-empty-message {
    text-align: center;
    width: 100%;
    color: #757575;
    font-style: italic;
    font-size: 14px;
}
