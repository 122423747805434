a.p-menuitem-link {
    font-family: $font-family-sans-serif;
    font-size: 12px;

    &:hover {
        text-decoration: none;
    }
}

.p-dropdown.p-focus {
    border-color: $input-focus-border-color;
    outline: 0;
    box-shadow: $input-focus-box-shadow;
}

.p-dropdown-panel {
 .p-dropdown-items {
     margin-bottom: 0px;
 }
}