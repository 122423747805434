﻿/* styles for file upload data */
.file-upload-data-container {

    .row-data-container {
        display: table;
        width: 100%;
    }

    .status-icon-container {
        display: table-cell;
        width: 45px;
        vertical-align: middle;

        .status-icon {
            font-size: 32px;
            background: white;
            border: 0;
        }
    }

    .file-info-container {
        .file-info {
            display: table-cell;
            max-width: 0;
            font-size: 16px;
        }

        // The parent element for the upload status, percent, file size etc info displayed below the progress bar
        .file-sub-info-parent {
            display: flex;
            justify-content: space-between; // Ensure items are positioned using the full width available
        }

        // Info items like upload status, percent, file size etc displayed below the progress bar
        .file-sub-info {
            padding-top: 2px;
            font-size: 13px;
        }

        .file-actions {
            display: table-cell;
            width: 1px;
            padding-left: 10px;
        }
    }

    .upload-info-container {
        table-layout: fixed;

        .upload-input-field {
            margin-bottom: 10px;
        }

        .fas, .far {
            color: #888888;
            font-size: 16px;
        }

        .selectize-input.full {
            background-color: #F1F1F1;
            text-overflow: ellipsis;
        }
    }

    .text-not-uploaded {
        color: #e0e0e0;
    }

    hr {
        margin-top: 5px;
        margin-bottom: 15px;
    }
}

