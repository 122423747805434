.p-autocomplete.p-focus,
.p-dropdown.p-focus,
.p-inputtext:enabled:focus,
.p-autocomplete-multiple-container,
.p-button:focus {
    box-shadow: none;
}

.p-autocomplete-panel {
    border-radius: 0;
    .p-autocomplete-items .p-autocomplete-item {
        padding: 3px 12px;
    }
}

.p-autocomplete .p-button,
.p-autocomplete .p-button:focus,
.p-autocomplete .p-button:hover {
    background: none;
    border: none;
    color: inherit;
}

.p-autocomplete {
    border: $background-light-grey;
    border-radius: 0;
    background-color: $background-light-grey;
    min-height: 34px;
    width: 100%;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    border: 1px solid $input-border-color;

    .dropdown-icon {
        transition: 0.25s;

        &.rotate {
            transform: rotate(180deg);
        }
    }

    .dropdown-spinner {
        position: absolute;
        right: 25px;
        top: 10px;
    }

    input.p-inputtext {
        padding-left: 12px;
    }

    .p-autocomplete-multiple-container li input::placeholder,
    input.p-inputtext::placeholder {
        color: lighten($input-color, 25%);
    }

    &.has-error {
        border-color: $validation-error-color;
    }
}

// highlight entire control including dropdown icon
.p-autocomplete:has(.p-autocomplete-input:enabled:focus),
.p-autocomplete:has(.p-autocomplete-multiple-container li input:enabled:focus) {
    border-color: $input-focus-border-color;
    outline: 0;
    box-shadow: $input-focus-box-shadow;

    &.has-error {
        border-color: $validation-error-color;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
    }
}

.p-autocomplete-input,
.p-autocomplete-multiple-container {
    border: none;
}

.p-inputtext {
    background: rgba(0, 0, 0, 0);
}

.p-autocomplete-items {
    margin-bottom: 0;
    .p-autocomplete-empty-message {
        padding: 0.5rem 1.5rem;
    }
}

.p-autocomplete-items .p-autocomplete-item,
.p-autocomplete-panel,
.p-inputtext,
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-family: $font-family-sans-serif;
    font-size: $font-size-base;
}

.p-autocomplete-multiple-container {
    margin-bottom: 0px;
    width: 100%;
    overflow: auto;
}

.p-autocomplete-item.p-disabled .dropdown-option {
    pointer-events: auto;
}

.p-autocomplete-item.p-disabled .dropdown-option:active {
    pointer-events: none;
}