﻿.btn-outline {
    background-color: transparent;
    color: inherit;
    transition: all .5s;
}

// Use default text colour for primary outline buttons for web accessibility colour contrast reasons

.btn-success.btn-outline {
    color: #5cb85c;
}

.btn-info.btn-outline {
    color: #5bc0de;
}

.btn-warning.btn-outline {
    color: #f0ad4e;
}

.btn-danger.btn-outline {
    color: #d9534f;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
    color: #fff;
}

.btn-sort-order {
    height: 34px;
    background-color: unset;
    font-size: 15px;
}
